<template>
  <div id="app">
    <nav>
      <div class="kakao">
        <!-- 카카오톡 -->
        <img src="@/assets/kakaotalk.png" alt="" @click="MoveKakao()">
        카카오톡
        <!-- <hr/>
        <img src="@/assets/banner.png" alt="" @click="MovePlatform()">
        애드플랫폼 -->
      </div>
      <div class="fullSection">
        <div class="headerSection">
          <div class="leftHeader">
            <img src="@/assets/banner.png" alt="">
            애드데이터
          </div>
          <div class="middleHeader">
            <div>
              <router-link to="/ask">애드데이터</router-link>
            </div>
            <!-- <div>
              <router-link to="/platform">애드플랫폼</router-link>
            </div> -->
            <div>
              <router-link to="/campaign">캠페인</router-link>
            </div>
            <div>
              <router-link to="/notice">공지사항</router-link>
            </div>
          </div>
          <div class="rightHeader" v-if="$store.state.clntNm == null || $store.state.clntNm == ''">
            <span @click="Login()">로그인&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <!-- <span>로그인&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
            <span @click="SignIn()">회원가입</span>
          </div>
          <div class="rightHeader" v-else>
            <span class="user" v-if="$store.state.adGradeCd == '03'">
              <span v-if     ="$store.state.adLevelCd == '01'" class="level">LV.1</span>
              <span v-else-if="$store.state.adLevelCd == '02'" class="level">LV.2</span>
              <span v-else-if="$store.state.adLevelCd == '03'" class="level">LV.3</span>
              <span v-else                                     class="level">일반</span>
              {{$store.state.clntNm}}님
            </span>
            <span class="user02" v-else>
              {{$store.state.clntNm}}님
            </span>
            <button class="logout" @click="LogOut()">로그아웃</button>
          </div>
        </div>
      </div>
      <div class="bodySection">
        <div class="bodyArea">
          <router-view/>
        </div>
      </div>
    </nav>
    <div id="footer">
      <div class="footerTop">
          애드데이터<br>
          경기도 광명시 일직로 43 GIDC C동 22층 2212호<br>
          대표자 : 양천수&nbsp;&nbsp;&nbsp;대표번호 : 010-5475-0927&nbsp;&nbsp;&nbsp;사업자등록번호 : 894-25-01407
      </div>
      <div class="footerBottom">
        <div class="top">
          COPYRIGHT© 2022 애드데이터. ALL RIGHTS RESERVED
        </div>
        <div class="bottom">
          <a href="javascript:void(0)" @click="PopUpTerms();">이용약관</a>
          <a href="javascript:void(0)" @click="PopUpPrivacy();">개인정보처리방침</a>
        </div>
      </div>
    </div>
    <use-terms></use-terms>
    <privacy-policy></privacy-policy>
    <Login></Login>
    <sign-in></sign-in>
  </div>
</template>

<script>

import $ from 'jquery';
import UseTerms from './components/UseTerms.vue'
import PrivacyPolicy from './components/PrivacyPolicy.vue'
import Login from './components/logIn.vue'
import signIn from './components/signIn.vue'

export default {
  // name: 'App',
  components: {
      UseTerms
    , PrivacyPolicy
    , Login
    , signIn
  },
  data() {
    return {
    }
  },
  methods: {
    //******************************************************************************
    // 로그인
    //******************************************************************************
    Login() {
      $("#logIn").css({display: "block"})
    },
    //*****************************************************************************
    // 회원가입
    //******************************************************************************
    SignIn() {
      $("#singBd").css({display: "block"})
    },
    //******************************************************************************
    // 로그아웃 함수
    //******************************************************************************
    LogOut() {
      // sessionStorage 전체를 지운다.
      sessionStorage.clear();
      localStorage.clear();
      
      this.$store.state.clntId       = null
      this.$store.state.clntNm       = null
      this.$store.state.nickNm       = null
      this.$store.state.jwtAuthToken = null
      this.$store.state.adGradeCd    = null
      this.$store.state.adLevelCd    = null
      this.$store.state.adId         = null
      this.$store.state.menuSelect = 1;

      this.$router.push({ 
        name : 'campaign', 
      })
    },






    //*****************************************************************************
    // 회원가입
    //******************************************************************************
    SignIn() {
      $("#singBd").css({display: "block"})
    },
    //******************************************************************************
    // 개인정보 처리 방침
    //******************************************************************************
    PopUpPrivacy () {
      $("#privacyPolicy").css({display: "block"})
    },
    //******************************************************************************
    // 이용약관
    //******************************************************************************
    PopUpTerms () {
      $("#useTerms").css({display: "block"})
    },
    //******************************************************************************
    // 카카오톡
    //******************************************************************************
    MoveKakao() {
      window.open('https://open.kakao.com/o/gsiqHere');
    },
    //******************************************************************************
    // 애드플랫폼
    //******************************************************************************
    MovePlatform() {
      window.open('https://www.adplatforms.co.kr');    
    }

  },
  created(){
    document.title = "애드데이터 홈페이지";
    window.scrollTo(0,0);

    this.$store.state.clntId       = sessionStorage.getItem("clntId");
    this.$store.state.clntNm       = sessionStorage.getItem("clntNm");
    this.$store.state.nickNm       = sessionStorage.getItem("nickNm");
    this.$store.state.jwtAuthToken = sessionStorage.getItem("token");
    this.$store.state.adGradeCd    = sessionStorage.getItem("grade");
    this.$store.state.adLevelCd    = sessionStorage.getItem("level");
    this.$store.state.adId         = sessionStorage.getItem("adId");
    
    this.$router.push({ name : 'campaign', });


  }
}
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

@font-face {
  font-family: "GmarketSansTTFMedium";
  src: url("../fonts/NanumGothic-Regular.ttf");
} */

@font-face {
  font-family: 'BMDOHYEON';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMDOHYEON.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face { font-family: "SCDream1"; src: url("../fonts/SCDream1.otf"); }
@font-face { font-family: "SCDream2"; src: url("../fonts/SCDream2.otf"); }
@font-face { font-family: "SCDream3"; src: url("../fonts/SCDream3.otf"); }
@font-face { font-family: "SCDream4"; src: url("../fonts/SCDream4.otf"); }
@font-face { font-family: "SCDream5"; src: url("../fonts/SCDream5.otf"); }
@font-face { font-family: "SCDream6"; src: url("../fonts/SCDream6.otf"); }
@font-face { font-family: "SCDream7"; src: url("../fonts/SCDream7.otf"); }
@font-face { font-family: "SCDream8"; src: url("../fonts/SCDream8.otf"); }
@font-face { font-family: "SCDream9"; src: url("../fonts/SCDream9.otf"); }

body {
  padding: 0px;
  margin: 0px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* font-family:'Malgun Gothic'; */

  /* -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none; */
}


.kakao {
  width: 60px;
  position: fixed;
  height: 110px;
  top: 150px;
  right: 50px;
  cursor: pointer;
  z-index: 999;
  padding-bottom: 120px;
  font-size: 12px;
}

.kakao img {
  width: 50px;
  height: 50px;
  top: 150px;
  cursor: pointer;  
}















nav {
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  font-family: 'SCDream5';
  
}
nav a {
  font-weight: bold;
  color: #9c9c9c;
  text-decoration-line: none;
  font-family: 'SCDream4';
}
nav a.router-link-exact-active {
  color: #1c221f;
  text-decoration-line: none;    

}
.fullSection {
  box-sizing: border-box;
  min-width: 100px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}
.fullSection img {
  position: relative;
  top: 0px;
  right: 0px;
}
.headerSection {
  margin: 0 auto;
  position: relative;
  height: 50px;
  top: 0px;
  box-sizing: border-box;
  margin-bottom: 0px;
  width: 1200px;
}
.headerSection .leftHeader {
  position: absolute;
  left: 0px;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  padding: 12px 0px;
}
.headerSection .leftHeader img {
  width: 22px;
}
.headerSection .middleHeader {
  left: 300px;
  position: absolute;
  display: flex;
  justify-content: space-around;  
  right: 0px;
  width: 600px;
  height: 100%;
  box-sizing: border-box;
  text-align: right;
  font-size: 14px;
  font-weight: 700;
  align-items: center;
}
.headerSection .rightHeader {
  display: inline-block;
  float: right;
  left: 900px;
  position: absolute;
  width: 298px;
  height: 100%;
  box-sizing: border-box;
  font-family: 'SCDream5';
  font-size: 12px;
  color: #777;
  padding: 18px 0px;
  text-align: right;
}
.headerSection .rightHeader .user {
    height: 30px;
    padding: 5px 8px 5px 50px;
    color: rgb(65, 65, 65);
    font-size: 12px;
    letter-spacing: -0.3px;
    position: relative;
    /* margin-top: -16px; */
  }
  .headerSection .rightHeader .user02 {
    height: 30px;
    padding: 5px 10px;
    display: inline-block;
    color: #444;
    font-size: 12px;
    letter-spacing: -0.3px;
    
  }
  .headerSection .rightHeader .level {
    position: absolute;
    display: inline-block;
    top: 2px;
    left: 9px;
    width: 31px;
    height: 21px;
    background: url("@/assets/levelBtn.png");
    /* background-color: #2c3e50; */
    padding-top: 3px;
    padding-left: 2px;
    text-align: center;
    color: #fff;
    font-size: 10px;

    
  }




















.headerSection .rightHeader span {
  cursor: pointer;
}

.headerSection .rightHeader button {
    width: 80px;
    margin-left: 5px;
    height: 30px;
    border: none;
    background-color: rgb(82, 105, 151);
    color: #fff;
    font-weight: 700;
    border-radius: 5px;
    margin-top: -8px;
    cursor: pointer;
  }
























.bodySection {
  margin: 0 auto;
  margin-bottom: 0px;
}

.bodySection .bodyArea {
  position: relative;
  
  margin: 0 auto;
  /* border: 1px solid #ccc; */
}
.footerSection {
  display: block;
  position: sticky;
  height: 100px;
  box-sizing: border-box;
  background-color: rgb(188, 203, 230);
}
#footer {
  border-top: 1px solid #e5e5e5;
  text-align: center;
}
#footer > div {
  margin: 0 auto;
  padding: 13px;
  text-align: left;
  width: 1200px;
}
#footer .footerTop {
  padding: 26px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;    
  font-size: 12px;
}
#footer .footerBottom .bottom {
  height: 13px;
  margin-top: -17px;
  margin-bottom: 20px;
}
#footer .footerBottom .bottom a {
  padding: 0 18px;
  float: right;
}
#footer .footerBottom .bottom a:last-child {
  position: relative;
}
#footer .footerBottom .bottom a:last-child::before {
  clear: both;
  content: "";
  width: 1px;
  height: 10px;
  left: -0.5px;
  top: 50%;
  background: #e0e0e0;
  position: absolute;
  transform: translateY(-50%);
}
#footer .top,
#footer .bottom,
#footer .bottom a {
  letter-spacing: -0.48px;
  color: #222;
  font-size: 12px;
}

.red {
  color: red;
}
</style>