<template>
<div id="singBd">
  <div id="singPopUp">
    <h1>애드데이터 회원가입</h1>
    <h3>서비스 이용약관</h3>
    <div class="termsBox">
      <p>
        <b>애드데이터(ADDATA) 이용약관</b><br>
        <br>
        <span>
          제1조 (목적) 이 약관은 <b>애드데이터</b>(이하 ‘회사’라 합니다)가 제공하는 '애드데이터(addatas.com)' 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항 기타 필요한 사항을 규정함을 목적으로 합니다.<br>
          <br>
          제2조 (약관의 효력 및 변경)<br>
          <span>
            1. 이 약관은 서비스를 통하여 이를 공지하거나 전자우편, 기타의 방법으로 회원에게 통지함으로써 효력을 발생합니다<br>
            2. 회사는 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지 또는 통지함으로써 효력을 발생합니다.<br>
            3. 회원은 신설 또는 변경된 약관에 동의하지 않을 경우 회원탈퇴를 요청할 수 있으며, 신설 또는 변경된 약관의 효력발생일 이후에도 서비스를 계속 사용할 경우 약관의 변경사항에 동의한 것으로 간주됩니다.
          </span>
          <br>
          <br>
          제3조 (약관의 해석) 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 ‘콘텐츠진흥법’, ‘전자상거래등에서의소비자보호에관한법률’, ‘약관의규제에관한법률’, 문화체육관광부장관이 정하는 ‘콘텐츠이용자보호지침’, 기타 관계법령 또는 상관례에 따릅니다.<br>
          <br>
          제4조 (용어의 정의) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br>
          <span>
            1. 회원 : 회사와 서비스 이용에 관한 계약을 체결한 자<br>
            2. 아이디(E-Mail) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합<br>
            3. 비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합<br>
            4. 해지 : 회사 또는 회원이 서비스 이용 이후 그 이용계약을 종료시키는 의사표시<br>
          </span>
          <br>
          <br>
          제5조 (서비스 이용계약의 성립)<br>
          <span>
            1. 서비스 가입 신청시 본 약관을 읽고 "동의합니다"의 버튼을 클릭하면 본 약관에 동의하는 것으로 간주됩니다.<br>
            2. 이용계약은 서비스이용신청자의 이용신청에 대하여 회사가 승낙을 함으로써 성립합니다.<br>
          </span>
          <br>
          <br>
          제6조 (이용신청)<br>
          <span>
            1. 서비스 이용신청자는 서비스를 통하여 회사 회원가입 신청서를 제출함으로써 이용신청을 할 수 있습니다.<br>
            2. 회원가입 신청양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로 간주됩니다. 실명이나 실제 정보를 입력하지 않은 사용자는 법적인 보호를 받을 수 없으며, 서비스사용의 제한을 받으실 수 있습니다.<br>
            3. 만 14세 미만의 미성년자가 서비스를 이용하기 위해서는 회사가 요청하는 법정대리인(부모) 동의 절차를 거쳐야 모든 서비스의 이용이 가능합니다.<br>
            4. 회사는 회원의 실명확인을 위하여 전문기관을 통하여 실명인증을 할 수 있습니다.<br>
          </span>
          <br>
          <br>
          제7조 (이용신청의 승낙)<br>
          <span>
            1. 회사는 제6조에 따른 이용신청에 대하여 특별한 사정이 없는 한 접수 순서에 따라서 이용신청을 승낙합니다.<br>
            2. 회사는 다음 각 호에 해당하는 경우 이용신청에 대한 승낙을 제한할 수 있고, 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다. <br>
            <span>
              1) 서비스 관련 설비의 용량이 부족한 경우<br>
              2) 기술상 장애사유가 있는 경우<br>
              3) 가입신청자가 본 약관 제9조 제3항에 의거하여 이전에 회원 자격을 상실한 적이 있는 경우<br>
              4) 등록내용에 허위, 기재누락, 오기가 있는 경우<br>
              5) 기타 회사가 필요하다고 인정되는 경우
            </span>
            <br>
            3. 만 14세 미만의 미성년자는 반드시 법정대리인(부모)의 가입동의를 받아야 정상적인 서비스 이용이 가능하며, 법정대리인의 가입동의 의사표시는 E-mail, 팩스 (0504-441-0927), 우편 등의 방법을 이용할 수 있습니다.<br>
            4. 만 18세 미만의 미성년자는 유료서비스 이용전에 반드시 법정대리인(부모)의 유료서비스 이용에 관한 동의를 받아야 정상적인 유료서비스가 가능하며, 법정대리인의 유료서비스 이용에 관한 동의 의사표시는 E-mail, 팩스 (0504-441-0927),우편 등의 방법을 이용할 수 있습니다.
          </span>
          <br>
          <br>
          제8조 (회원 아이디(ID)의 변경) 다음 각 호에 해당하는 경우 회사는 직권 또는 회원의 신청에 의하여 회원 아이디(ID)를 변경할 수 있습니다.<br>
          <span>
            1. 회원 아이디(ID)가 회원의 전화번호, 주민등록번호 등으로 등록되어 있어서 회원의 사생활을 침해할 우려가 있는 경우<br>
            2. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우<br>
            3. 기타 회사 소정의 합리적인 사유가 있는 경우
          </span>
          <br>
          <br>
          제9조 (계약의 해지 및 이용제한)<br>
          <span>
            1. 회원이 서비스 이용계약을 해지하고자 하는 경우에는 본인이 서비스 또는 전자우편을 통하여 회원탈퇴신청을 하여야 하며 회원의 탈퇴신청에 대해 회사는 빠른 시간 내로 탈퇴처리를 할 의무가 있습니다.<br>
            2. 회원이 사망한 때는 회원자격을 상실합니다.<br>
            3. 회사는 회원이 제21조 회원의 의무에 위배되는 행위를 한 경우 사전통지 없이 서비스 이용계약을 해지하거나 회원자격을 적절한 방법으로 제한 및 정지할 수 있습니다.
          </span>
          <br>
          <br>
          제10조 (회원 자격 및 회원자격의 해지, 제한, 정지의 절차)<br>
          <span>
            1. 제 5, 6, 7조에 의해 회원자격을 취득한 회원은 동일한 아이디로 회사에서 제공하는 애드데이터(ADDATA)를 이용할 수 있습니다.<br>
            2. 회사는 제9조 제3항의 규정에 의하여 회원자격을 해지, 제한 또는 정지하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 전자우편, 서면 또는 전화 등의 방법에 의하여 해당 회원 또는 대리인에게 통지합니다. 다만, 회사가 긴급하게 회원의 서비스 이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니합니다.<br>
            3. 제2항에 의하여 이용제한의 통지를 받은 회원 또는 그 대리인이 이용제한 통지에 대하여 이의가 있는 경우에는 이의신청을 할 수 있습니다.<br>
            4. 회사는 제3항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용제한을 일시 연기할 수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.<br>
            5. 회사는 이용제한기간 중에 그 사유가 해소된 것이 확인된 경우에는 이용제한 조치를 즉시 해제합니다.
          </span>
          <br>
          <br>
          제11조 (회원에 대한 통지)<br>
          <span>
            1. 회사가 회원에 대해 통지를 하는 경우, 회원이 회사에 제출한 전자우편 주소로 할 수 있습니다.<br>
            2. 회사는 불특정다수 회원에 대한 통지의 경우 서비스 게시판 또는 채팅방에 게시함으로써 개별 통지에 갈음할 수 있습니다.
          </span>
          <br>
          <br>
          제12조 (서비스의 내용 및 이용)<br>
          <span>
            1. 회사가 제공하는 서비스의 내용은 다음과 같습니다.<br>
            <span>
              애드데이터(ADDATA)<br>
              1) 디비수집과 관련된 서비스<br>
              2) 기타 회사가 자체적으로 개발하거나 타사와의 협력 관계, 계약 등을 통해 회원에게 제공할 일체의 서비스
            </span>
            <br>
            2. 회사는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다. 이 경우 회사는 추가 또는 변경내용을 본 약관 제11조에 따라 회원에게 통지합니다.<br>
            3. 회사가 제공하는 유료서비스를 미성년자가 이용하기 위해서는, 민법 5조 1항에 따라 법정대리인의 동의를 받은 후에만 결제 할 수 있습니다. 또한 미성년자의 법정대리인이 미성년자의 결제에 대하여 이의를 제기할 경우, 회사는 그에 대한 입증책임을 집니다, 단, 법정대리인의 동의에 대하여 미성년자의 사술이 사용된 경우에는 회사는 그 사항에 대하여 책임을 지지 않습니다.<br>
            4. 미성년자의 유료서비스 사용을 위한 법정대리인의 동의방법은 회사가 정하는 동의 방법에 따릅니다.
          </span>
          <br>
          <br>
          제13조 (서비스의 요금) 회사가 제공하는 애드데이터(ADDATA)서비스는 기본적으로 유료입니다.<br>
          <span>
            1.회원은 회사가 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. 회사는 계약 체결 전에 각 호의 사항에 관하여 회원이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.<br>
            <span>
              1)유료서비스의 확인 및 선택<br>
              2)결제방법의 선택 및 결제정보의 입력<br>
              3)유료서비스의 이용신청에 관한 확인 또는 회사의 확인에 대한 동의
            </span>
            <br>
            2.회사는 회원의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.<br>
            <span>
              1)실명이 아니거나 타인의 명의를 이용한 경우<br>
              2)허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우<br>
              3)미성년자가 ‘청소년보호법’ 등 관련 법령에 의해서 이용이 금지되는 유료서비스를 이용하고자 하는 경우<br>
              4)서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우
            </span>
            <br>
            3.회원이 유료서비스를 이용하기 위해서는 이 약관에 동의 후 각 서비스에 따른 이용조건에 따라 이용요금을 지급하여야 합니다.
          </span>
          <br>
          <br>
          제14조 (서비스의 개시 서비스는 회사가 제7조에 따라서 이용신청을 승낙한 때로부터 즉시 개시됩니다. 다만, 회사의 업무상 또는 기술상의 장애로 인하여 서비스를 즉시 개시하지 못하는 경우 회사는 회원에게 이를 통지합니다.<br>
          <br>
          <br>
          제15조 (서비스 이용시간)<br>
          <span>
            1. 서비스는 회사의 업무상 또는 기술상 장애, 기타 특별한 사유가 없는 한 연중무휴, 1일 24시간 이용할 수 있습니다. 다만 설비의 점검 등 회사가 필요한 경우 또는 설비의 장애, 서비스 이용의 폭주 등 불가항력 사항으로 인하여 서비스 이용에 지장이 있는 경우 예외적으로 서비스 이용의 전부 또는 일부에 대하여 제한할 수 있습니다.<br>
            2. 회사는 제공하는 서비스 중 일부에 대한 서비스이용시간을 별도로 정할 수 있으며, 이 경우 그 이용시간을 사전에 회원에게 공지 또는 통지합니다.
          </span>
          <br>
          <br>
          제16조 (정보의 제공 및 광고의 게재)<br>
          <span>
            1. 회사는 서비스를 운용함에 있어서 각종 정보를 서비스에 게재하는 방법 등으로 회원에게 제공할 수 있습니다.<br>
            2. 회사는 서비스의 운용과 관련하여 서비스 화면, 전자우편 등에 광고 등을 게재할 수 있습니다.<br>
            3. 본 서비스의 회원은 서비스 이용 시 노출되는 광고게재에 대해 동의하는 것으로 간주됩니다.<br>
            4. 회사는 서비스 상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든 손실과 손해에 대해 책임을 지지 않습니다.
          </span>
          <br>
          <br>
          제17조 (서비스 제공의 중지)<br>
          <span>
            1.회사는 다음 각 호에 해당하는 경우 서비스의 제공을 완전히 중지할 수 있습니다. <br>
            <span>
              1) 설비의 보수 등을 위하여 부득이한 경우<br>
              2) 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하는 경우<br>
              3) 기타 회사가 서비스를 제공할 수 없는 사유가 발생한 경우.<br>
              4) 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
            </span><br>
            2. 회사가 통제할 수 없는 사유로 인한 서비스의 중단(시스템 관리자의 고의 및 과실이 없는 디스크 장애, 시스템 다운 등)으로 인하여 사전 통지가 불가능한 경우에는 이용자에게 사전 통지하지 않아 회원에게 발생한 책임을 지지 않습니다.
          </span>
          <br>
          <br>
          제18조 (서비스의 요금 환불/취소 정책)<br>
          <span>
            1. 서비스 이용에 따른 환불정책은 다음과 같습니다. 단, 회원의 귀책사유로 회사가 이용계약을 직권해지하는 경우 환불하지 않습니다.<br>
            2. 결제일로 부터 5일이내 해지 신청 시 서비스 신청금액에서 해지수수료로 신청금액의 20%를 공제한 나머지 금액이 환불됩니다.(단,서비스개시이용전인경우만 해당함)<br>
            3. 결제일로 부터 5일 초과 20일 이내 해지 신청 시 서비스 신청금액에서 해지수수료로 서비스 신청금액의 20%를 공제한 나머지 금액을 기준으로 남은 잔여일에 해당하는 금액이 환불됩니다. 즉 환불액은 [서비스 신청금액 *0.80] - [(서비스 신청금액/30일) * 서비스 사용일]이 됩니다.<br>
            4. 단, 휴대폰결제는 3일 이내 취소만 가능하며 3일 이후는 환불 및 취소가 불가능합니다
          </span>
          <br>
          <br>
          제19조 (회사의 의무)<br>
          <span>
            1. 회사는 제15조 및 제17조에서 정한 경우를 제외하고 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다하여야 합니다.<br>
            2. 회사는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로 유지 보수하고, 장애가 발생하는 경우 지체 없이 이를 수리, 복구할 수 있도록 최선의 노력을 다하여야 합니다.<br>
            3. 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 또는 전자우편을 통하여 동 회원에게 통지하여야 합니다.
          </span>
          <br>
          <br>
          제19조의2 (보안상 긴급상황)<br>
          <span>
            1. 보안상 심각하고 시급을 요하는 프로그램 결함이나 장애 혹은 그에 준하는 사건발생 시, 회사에서 고객의 해당 부분을 일괄적으로 패치를 할 수 있습니다.<br>
            2. 보안상 심각하고 시급을 요하는 경우 회사에서 고객의 인증관련 정보를 응급 변경할 수 있습니다.<br>
            3. 본조 제1., 2.항의 긴급상황 대처 전 회사는 공지나 이메일을 통하여 고객에게 이를 알려야 합니다. 만약 상황이 긴급하여 이를 알리기에 어려움이 있다면, 회사는 대처 후라도 이를 공지나 이메일을 통하여 알려야 합니다.
          </span>
          <br>
          <br>
          제20조 (개인정보의 보호)<br>
          <span>
            1. 회사는 관련법령이 정하는 바에 따라서 서비스와 관련하여 기득한 회원의 개인정보를 보호하기 위해서 노력합니다. 회원의 개인정보 보호에 관해서는 관련법령 및 회사가 정하는 "개인정보 보호정책"에 정한 바에 의합니다.<br>
            2. 회사는 만 14세 미만의 아동이 가입을 원할 경우 개인정보보호지침 및 개인정보 보호정책에 의거 회원의 개인 정보를 수집하지 않습니다.
          </span>
          <br>
          <br>
          제21조 (회원의 의무)<br>
          <span>
            1. 회원은 관계법령, 이 약관의 규정, 이용안내 및 주의사항 등 회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.<br>
            2. 회원은 회사의 사전승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과와 회원이 약관에 위반한 영업활동을 하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다. 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우 회원은 회사에 대하여 손해배상의무를 집니다.<br>
            3. 회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판, 방송, 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.<br>
            4. 회원은 이용신청서의 기재내용 중 변경된 내용이 있는 경우 서비스를 통하여 그 내용을 회사에 통지하여야 합니다.<br>
            5. 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 아니 됩니다.<br>
            <span>
              1) 다른 회원의 아이디(ID)를 부정사용하는 행위<br>
              2) 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위<br>
              3) 선량한 풍속, 기타 사회질서를 해하는 행위<br>
              4) 타인의 명예를 훼손하거나 모욕하는 행위<br>
              5) 타인의 지적재산권 등의 권리를 침해하는 행위<br>
              6) 타인에 대한 개인정보를 수집 또는 저장하는 행위<br>
              7) 스토킹(stalking) 등 타인을 괴롭히는 행위<br>
              8) 해킹행위 또는 컴퓨터바이러스의 유포행위<br>
              9) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위<br>
              10) 불특정 다수를 대상으로 하여 광고, 선전의 게시 등 영리를 목적으로 하는 행위<br>
              11) 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위<br>
              12) 관계법령에 위배되는 행위<br>
              13) 기타 회사가 서비스 운영상 부적절하다고 판단하는 행위
            </span><br>
            6. 회사가 관계법령 및 "개인정보보호정책"에 의하여 그 책임을 지는 경우를 제외하고, 회원에게 부여된 ID의 비밀번호의 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.<br>
            7.회원은 자신의 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 회사에 신고하여야 하며, 회사의 안내가 있는 경우에는 그에 따라야 합니다. 이를 따르지 않는 경우 이에 따른 모든 결과의 책임은 회원에게 있습니다.
          </span>
          <br>
          <br>
          제22조 (게시물 또는 내용물의 삭제) 회사는 회원이 게시하거나 등록하는 서비스 내의 내용물등이 제21조의 규정에 위반되거나 회사 소정의 게시기간을 초과하는 경우 사전 통지나 동의 없이 이를 삭제할 수 있습니다.<br>
          <br>
          제23조 (회원의 아이디(ID) 및 비밀번호에 대한 의무)<br>
          <span>
            1. 회원은 서비스를 이용하는 경우 아이디(ID) 및 비밀번호를 사용해야 합니다.<br>
            2. 아이디(ID) 및 비밀번호에 대한 모든 관리의 책임은 회원에게 있습니다.<br>
            3. 회원은 아이디(ID) 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.<br>
            4. 아이디(ID) 및 비밀번호의 관리상 불충분, 사용상의 과실, 제3자의 사용 등에 의한 손해의 책임은 회원이 집니다.<br>
            5. 회원은 아이디(ID) 및 비밀번호를 도난당하거나 제3자에게 사용되고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 지시가 있는 경우에는 그에 따라야 합니다.
          </span>
          <br>
          <br>
          제24조 (저작권의 귀속 및 이용제한)<br>
          <span>
            1. 회사가 작성한 서비스에 관한 저작권 및 기타 지적재산권은 회사에 귀속됩니다.<br>
            2. 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나, 제 3자에게 이용하게 하여서는 안됩니다. (단, 회원이 사적으로 이용하는 경우는 그러하지 아니합니다.)<br>
            3. 회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다. 단, 회원이 회사에 제공한 공개적인 게시물에 대한 권리는 회사와 개인이 공동으로 소유합니다.
          </span>
          <br>
          <br>
          제25조 (양도금지) 회원이 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
          <br>
          <br>
          제26조 (손해배상) 회사는 서비스에서 제공하는 서비스의 이용과 관련하여 개인정보보호정책에서 정하는 내용에 해당하지 않는 사항에 대하여 어떠한 손해도 책임을 지지 않습니다.
          <br>
          <br>
          제27조 (면책)<br>
          <span>
            1. 회사는 회원의 채팅 내용을 감시하지 않습니다. 채팅 내용에 대한 책임은 각 회원에게 있습니다. 본 서비스를 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 행위를 하여 발생하는 모든 법적인 책임은 회원에게 있으며, 그 해당 아이디(ID)와 관련 데이터는 보호 받을 수 없습니다.<br>
            2. 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니하고, 회원은 자기의 책임아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사선택, 기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 회원에게 있습니다.<br>
            3. 회사가 제공하는 정보와 자료는 거래의 목적으로 이용될 수 없습니다. 따라서 본 서비스의 정보와 자료 등에 근거한 거래는 전적으로 회원자신의 책임과 판단아래 수행되는 것이며, 회사는 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.<br>
            4. 회원 아이디(ID)와 비밀번호의 관리 및 이용상의 부주의로 인하여 발생되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 모두 회원에게 있습니다.
          </span>
          <br>
          <br>
          제28조 (랜딩 스팸전송자 이용제한)<br>
          <span>
            1. 회사와 회원은 광고성 정보를 전달함에 있어 스팸방지와 관련법령 및 가이드 등을 준수합니다.<br>
            2. 회사는 다음 각호에 해당하는 경우 서비스의 이용을 제한합니다.<br>
            <span>
              1) 방송통신위원회 또는 한국인터넷진흥원이 불법스팸 전송사실을 확인하여 이용정지를 요청하는 경우<br>
              2) 대량으로 스팸을 전송하여 시스템 장애를 야기했거나 야기할 우려가 있는 경우<br>
              3) 스팸릴레이로 이용되거나 웜 등에 감염되어 스팸을 전송한 경우<br>
              4) 스팸 수신자로부터 스팸신고가 접수된 경우<br>
              5) 스팸 민원 조치에 대한 회사의 요구에 회원이 성실히 응하지 아니한 경우<br>
              6) 스팸메일 발송과 관련하여 국내/외 스팸 메일 감시단체 등에 블랙리스트로 등재된 경우
            </span><br>
            3. 회사는 스팸 수신거부처리 등을 위해 스팸 고충처리창구를 아래와 같이 운영합니다.<br>
            &nbsp;&nbsp;※ 연락처 : 010-5475-0927, 전자우편 :adceo@addatas.com, 신고접수 : addatas.com /고객센터 게시판<br>
            6. 회사는 회원이 스팸을 전송한 사실을 확인하는 경우 한국인터넷진흥원 불법스팸대응센터에 관련 자료를 첨부하여 신고할 수 있습니다.<br>
            7. 회사는 스팸전송으로 인해 발생된 문제는 책임이 없습니다.
          </span>
          <br>
          <br>
          제29조 (분쟁의 해결) 회사는 유료서비스의 하자 등에 의한 회원의 피해보상 기준, 범위, 방법 및 절차에 관한 사항을 ‘콘텐츠이용자보호지침’에 따라 처리합니다.<br>
          <span>
            1. 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.<br>
            2. 제1항의 규정에도 불구하고, 동 분쟁으로 인하여 소송이 제기될 경우 동 소송은 회사의 본사소재지를 관할하는 법원의 관할로 합니다.
          </span>
          <br>
          <br>
          [부칙] 이 약관은 2022년 4월 1일부터 적용됩니다.<br>
        </span>
      </p>
    </div>
    <div class="radioBox">
      <input type="radio" ref="termsYn" name="termsYn" id="termsY" v-model="registerObj.termsYn" value="Y"><label for="termsY">동의합니다.</label>
      <input type="radio"               name="termsYn" id="termsN" v-model="registerObj.termsYn" value="N"><label for="termsN">동의하지 않습니다.</label>
    </div>
    <h3> 개인정보취급방침</h3>
    <div class="termsBox">
      <p>
        <b>애드데이터(ADDATA) 개인정보취급방침</b><br>
        <br>
        <span>
          <b>애드데이터</b> ('addatas.com'이하 '애드데이터')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br>
          &nbsp; ○ 이 개인정보처리방침은 2022년 4월 1부터 적용됩니다.
          <br>
          <br>
          제1조(개인정보의 처리 목적) <b>애드데이터</b> ('addatas.com'이하 '애드데이터')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br>
          <span>
            1. 홈페이지 회원가입 및 관리<br>
            &nbsp;회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.<br>
            2. 민원사무 처리<br>
            &nbsp;민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.<br>
            3. 재화 또는 서비스 제공<br>
            서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.<br>
            4. 마케팅 및 광고에의 활용<br>
            &nbsp;신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br>
            5. 개인영상정보<br>
            &nbsp;범죄의 예방 및 수사, 시설안전 및 화재예방 등을 목적으로 개인정보를 처리합니다.<br>
            6. SNS(네이버,카카오,페이스북)인증<br>
            &nbsp;이용자가 직접 해당 SNS서비스에 로그인하여 회사에게 개인정보 제공을 허용함에 동의해야 하며, 동의 및 인증 후 회사는 이용자가 가입 버튼을 눌러 완료한 후에만 Access Token을 이용해 사용자의 개인정보를 받아 저장합니다. Access Token은 가입시 일회만 사용되며 가입 완료 후 사용자가 제공에 동의한 정보(이름,이메일)를 저장합니다.
          </span>
          <br>
          <br>
          제2조(개인정보의 처리 및 보유 기간)<br>
          <span>
            1. <b>애드데이터</b>는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br>
            2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
            <span>
              원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.<br>
              단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다. <br>
              [ 홈페이지 회원가입 및 관리 ]와 관련한 개인정보는 수집.이용에 관한 동의일로부터 [ 영구 ]까지 위 이용목적을 위하여 보유.이용됩니다.<br>
              - 보존 항목 : 회원가입정보(로그인ID, 이름, 별명)<br>
              - 보존 근거 : 회원탈퇴 시 다른 회원이 기존 회원아이디로 재가입하여 활동하지 못하도록 하기 위함<br>
              - 관련법령 : <br>
              <span>
                1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br>
                2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
                3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br>
                4) 계약 또는 청약철회 등에 관한 기록 : 5년<br>
                5) 표시/광고에 관한 기록 : 6개월
              </span>
            </span>
          </span>
          <br>
          <br>
          <br>
          제3조(개인정보의 제3자 제공)<br>
          <span>
            1. <b>애드데이터</b>는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br>
            2. <b>애드데이터</b>는 귀하의 개인정보를 "개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업·기관에 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.<br>
            <span>
              -통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br>
              -도용방지를 위하여 본인확인에 필요한 경우<br>
              -법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우<br>
              그러나 보다 나은 서비스 제공을 위하여 귀하의 개인정보를 제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 귀하께 제휴사가 누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게 보호·관리되는지에 대해 개별적으로 전자우편 및 서면을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 귀하께서 동의하지 않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다.<br>
              또한 이용자의 개인정보를 원칙적으로 외부에 제공하지 않으나, 아래의 경우에는 예외로 합니다.<br>
              -이용자들이 사전에 동의한 경우<br>
              -법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
            </span>
          </span>
          <br>
          <br>
          제4조(개인정보처리 위탁)<br>
          <span>
            1. <b>애드데이터</b>은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br>
            <span>
              1) (주)모비윈 <br>
              개인정보를 제공받는 자 : (주)모비윈<br>
              제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 생년월일, 이름, 신용카드정보, 은행계좌정보<br>
              제공받는 자의 보유.이용기간: 회원 탈퇴 시 혹은 계약 종료 시까지<br>
              2) (주)누리고<br>
              개인정보를 제공받는 자 : (주)누리고<br>
              제공받는 자의 개인정보 이용목적 : 휴대전화번호<br>
              제공받는 자의 보유.이용기간: 회원 탈퇴 시 혹은 계약 종료 시까지
            </span>
            <br>
            2. <b>애드데이터</b>는 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br>
            3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
          </span>
          <br>
          <br>
          제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)<br>
          <span>
            1. 정보주체는 <b>애드데이터</b>에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br>
            2. 제1항에 따른 권리 행사는<b>애드데이터</b>에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 <b>애드데이터</b>은(는) 이에 대해 지체 없이 조치하겠습니다.<br>
            3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br>
            4. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br>
            5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br>
            6. <b>애드데이터</b>은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
          </span>
          <br>
          <br>
          제6조(처리하는 개인정보의 항목 작성)<br>
          <span>
            <b>애드데이터</b>는 다음의 개인정보 항목을 처리하고 있습니다.<br>
            <span>
              [ 홈페이지 회원가입 및 관리 ]<br>
              필수항목 : 닉네임, 이메일, 휴대전화번호, 비밀번호, 로그인ID, 이름(법인:상호), 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록<br>
              선택항목 : 신용카드정보, 은행계좌정보, 법정대리인 이름, 법정대리인 휴대전화번호
            </span>
          </span>
          <br>
          <br>
          제7조(개인정보의 파기)<br>
          <span>
            회원탈퇴는 내정보->회원탈퇴 메뉴를 통해서 즉시 탈퇴가 가능합니다.<br>
            SNS계정(페이스북,네이버,카카오)을 통한 로그인또한 내정보->회원탈퇴에서 이용이 가능하며 SNS제공업체에서 연동을 해제 할수 있습니다. 그러나 SNS 연동해제를 하더라도 '애드데이터'에 보관중인 고객정보가 삭제되는 것은 아니므로 회원탈퇴를 진행하셔야 개인정보가 파기됩니다.<br>
            <br>
            <span>
              1. <b>애드데이터</b>는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br>
              2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br>
              고객이 탈퇴를 요청하는 즉시 개인정보가 파기가되며 동일 정보의 재가입방지를 위해 고유정보(아이디)를 제외하고는 즉시 파기합니다. <br>
              3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br>
              <span>
                1) 파기절차<br>
                <b>애드데이터</b>는 파기 사유가 발생한 개인정보를 선정하고, <b>애드데이터</b> 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br>
                2) 파기방법<br>
                -전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.<br>
                -종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
              </span>
            </span>
          </span>
          <br>
          <br>
          제8조(개인정보의 안전성 확보 조치)<br>
          <span>
            <b>애드데이터</b>는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br>
            <span>
              1. 정기적인 자체 감사 실시<br>
              &nbsp; 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.<br>
              2. 개인정보 취급 직원의 최소화 및 교육<br>
              &nbsp; 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br>
              3. 내부관리계획의 수립 및 시행<br>
              &nbsp; 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br>
              4. 해킹 등에 대비한 기술적 대책<br>
              &nbsp; <b>애드데이터</b>('애드데이터')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된<br>
              &nbsp; 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br>
              5. 개인정보의 암호화<br>
              &nbsp; 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도<br>
              &nbsp; 보안기능을 사용하고 있습니다.<br>
              6. 접속기록의 보관 및 위변조 방지<br>
              &nbsp; 개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는<br>
              &nbsp; 2년이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.<br>
              7. 개인정보에 대한 접근 제한<br>
              &nbsp; 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의<br>
              &nbsp; 무단 접근을 통제하고 있습니다.<br>
              8. 문서보안을 위한 잠금장치 사용<br>
              &nbsp; 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br>
              9. 비인가자에 대한 출입 통제<br>
              &nbsp; 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br>
            </span>
          </span>
          <br>
          <br>
          제9조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)<br>
          <span>
            1. <b>애드데이터</b>는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.<br>
            2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br>
            <span>
              1)쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br>
              2)쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.<br>
              3)쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
            </span>
          </span>
          <br>
          <br>
          제10조 (개인정보 보호책임자)<br>
          <span>
            <b>애드데이터</b>는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br>
            <span>
            ▶ 개인정보 보호책임자<br>
            성명 : 양천수<br>
            직책 : 대표<br>
            대표전화 :010-5475-0927, 이메일: adceo@addatas.com, 팩스: 0504-441-0927<br>
            </span>
            ※ 개인정보 보호 담당부서로 연결됩니다.<br>
            <b>애드데이터</b> 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. <b>애드데이터</b>는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
          </span>
          <br>
          <br>
          제11조(개인정보 열람청구)<br>
          <span>
            정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.<br>
            <b>애드데이터</b>은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br>
            <span>
              ▶ 개인정보 열람청구 접수·처리 부서<br>
              성명 : 양천수<br>
              직책 : 대표<br>
              대표전화 :010-5475-0927, 이메일: adceo@addatas.com, 팩스: 0504-441-0927
            </span>
          </span>
          <br>
          <br>
          제12조(권익침해 구제방법)<br>
          <span>
            정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br>
            <span>
              1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br>
              2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br>
              3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br>
              4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
            </span><br>
            
            「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
          </span>
          <br>
          <br>
          제13조(개인정보 처리방침 변경)<br>
          이 개인정보처리방침은 2022년 4월 1부터 적용됩니다.
        </span>
      </p>
    </div>
    <div class="radioBox">
      <input type="radio" ref="policyYn" name="policyYn" id="policyY" v-model="registerObj.policyYn" value="Y"><label for="policyY">동의합니다.</label>
      <input type="radio"                name="policyYn" id="policyN" v-model="registerObj.policyYn" value="N"><label for="policyN">동의하지 않습니다.</label>
    </div>
    <h3>정보입력</h3>
    <div class="tableBox">
      <table>
        <tr>
          <th>이메일(아이디)</th>
          <td class="tableWi">
            <input type="text" placeholder="abc@abd.co.kr" v-model="registerObj.clntId" ref="clntId">
          </td>
          <th>이름/회사명</th>
          <td>
            <input type="text" class="wMax" v-model="registerObj.clntNm" ref="clntNm">
          </td>
        </tr>
        <tr>
          <th>비밀번호</th>
          <td class="tableWi">
            <input type="password" name="clntPw" id="clntPw" v-model="registerObj.clntPw" ref="clntPw">
            <!-- <label for="clntPw">* 최소 8자 이상 13자 이내 (영문 , 숫자 ,특수문자 조합 사용) </label> -->
          </td>
          <th>비밀번호 확인</th>
          <td>
            <input type="password" name="clntPwConfirm" id="clntPwConfirm" v-model="registerObj.clntPwConfirm" ref="clntPwConfirm">
            <!-- <label for="clntPwConfirm">* 비밀번호 확인을 위해 다시 한번 입력 해 주세요. </label> -->
          </td>
        </tr>
        <tr>
          <th>휴대전화번호</th>
          <td class="tableWi">
            <input type="tel" id="clntSubsNo" v-model="registerObj.clntSubsNo" :disabled="registerObj.smsCheck" ref="clntSubsNo">
              <!-- <button @click="CheckSms();" id="checkBtn">본인확인</button> -->
            <div class="certain">
              <span>
                <input type="text" v-model="registerObj.smsNo">
                <button @click="CheckNo();">인증하기</button>
              </span>
            </div>
          </td>
          <th>추천인 코드</th>
          <td>
            <input type="text" name="userPwCon" id="sponserCd" v-model="registerObj.sponserCd" ref="sponserCd">
          </td>
        </tr>
      </table>
    </div>
    <div class="signBtnBox">
      <button class="signBtn" @click="SignPost()">
        가입하기
      </button>
      <button class="signCancleBtn" @click="SignMadalCancle()">
        취소하기
      </button>
    </div>
  </div>
</div>
</template>

<script>
  import $ from 'jquery';
  import axios from 'axios';

  export default {
    data() {
      return {
        registerObj: {
            clntId         : ''
          , clntPw         : ''
          , clntPwConfirm  : ''
          , clntNm         : ''
          , clntSubsNo     : ''
          , adGradeCd      : '03'
          , termsYn        : ''
          , policyYn       : ''
          , sponserCd      : ''
          , smsRequest     : ''
          , smsNo          : ''
          , smsCheck       : false
        }
      }
    },
    methods: {
      //******************************************************************************
      // 회원가입 취소닫기 함수
      //******************************************************************************
      SignMadalCancle() {
        $("#singBd").css({
          display: "none"
        })
      }
      //******************************************************************************
      // 회원가입 데이터 전송함수
      //******************************************************************************
      , SignPost() {
        if (this.registerObj.termsYn == 'N' || this.registerObj.termsYn == '') {
          alert('서비스 이용약관에 동의해 주세요.');
          this.$refs.termsYn.focus();
          return;
        }

        if (this.registerObj.policyYn == 'N' || this.registerObj.policyYn == '') {
          alert('개인정보취급방침에 동의해 주세요.');
          this.$refs.policyYn.focus();
          return;
        }

        if (this.registerObj.clntId == null || this.registerObj.clntId == '') {
          alert('이메일(아이디)를 입력해 주세요.');
          this.$refs.clntId.focus();
          return;
        }

        if (this.registerObj.clntNm == null || this.registerObj.clntNm == '') {
          alert('이름/회사명을 입력해 주세요.');
          this.$refs.clntNm.focus();
          return;
        }

        if (this.registerObj.clntPw == null || this.registerObj.clntPw == '') {
          alert('비밀번호를 입력하세요');
          this.$refs.clntPw.focus();
          return;
        }

        if (this.registerObj.clntPwConfirm == null || this.registerObj.clntPwConfirm == '') {
          alert('비밀번호 확인을 입력하세요');
          this.$refs.clntPwConfirm.focus();
          return;
        }

        if (this.registerObj.clntPw != this.registerObj.clntPwConfirm) {
          alert('패스워드가 동일하지 않습니다.');
          this.$refs.clntPwConfirm.focus();
          return;
        }

        if (this.registerObj.clntSubsNo == null || this.registerObj.clntSubsNo == '') {
          alert('휴대전화번호를 입력하세요.');
          this.$refs.clntSubsNo.focus();
          return;
        }

        if(confirm("회원가입을 진행하시겠습니까?") == false) {
           return;
         }

        // if( this.registerObj.smsCheck == false ){
        //   alert('본인인증 후 가입 가능합니다. 본인확인을 진행해주시기 바랍니다.');
        //   return
        // }

        //------------------------------------------------------------------------------
        // 정보 보내기
        //------------------------------------------------------------------------------
        var data = {
            clntId     : this.registerObj.clntId
          , clntPw     : this.registerObj.clntPw
          , clntNm     : this.registerObj.clntNm
          , clntSubsNo : this.registerObj.clntSubsNo
          , adGradeCd  : this.registerObj.adGradeCd
          , sponserCd  : this.registerObj.sponserCd
          , mbId       : 10000  // 이건 절대 바꾸지 말기! 바꾸면 작살남.
        };

        const frm = new FormData();
        frm.append("dataObj", new Blob([JSON.stringify(data)], {
          type: "application/json"
        }));

        axios.post("https://addatas.com:28500/CreateUser", frm)
        .then(response => {
          if (response.data.status == true) {
            $("#singBd").css({
                display: "none"
            })

            this.loginView = false;
            this.$store.state.clntId       = response.data.clntId
            this.$store.state.jwtAuthToken = response.data.jwtAuthToken
            this.$store.state.adGradeCd    = response.data.adGradeCd

            // 토큰값을 LocalStorage에 저장한다.
            sessionStorage.setItem("clntId", this.$store.state.clntId);
            sessionStorage.setItem("token" , this.$store.state.jwtAuthToken);
            sessionStorage.setItem("grade" , this.$store.state.adGradeCd);

            alert("회원가입이 완료되었습니다.\n\n로그인을 진행하시기 바랍니다.");
          } else {
            alert(response.data.message)
          }
        })
        .catch(error => {
          console.log(error);
        })
      },
      CheckSms() {
        if( this.registerObj.smsCheck == true ){
          return
        }

        if (this.registerObj.clntSubsNo == null || this.registerObj.clntSubsNo == '') {
          alert('휴대전화번호를 입력하세요.')
          return;
        }

        $('#singPopUp table td .certain span').css({display : "inline"})

        axios.get("https://addatas.com:28500/InsertAuthSms", {
          params: {
            to: this.registerObj.clntSubsNo,
            from: '15333757'
          }
        })
        .then(response => {
          this.registerObj.smsRequest = response.data.no;
          alert("인증번호가 발송되었습니다.")
        })
        .catch(error => {
          console.log(error);
        })
      },
      CheckNo() {
        if(this.registerObj.smsNo == this.registerObj.smsRequest) {
          alert("휴대폰 인증이 완료되었습니다.")
          $('#singPopUp table td .certain').css({display: "none"})
          this.registerObj.smsCheck = true

          $('#singPopUp table td #checkBtn').html('인증완료');

        }
        else {
          alert("인증번호가 동일하지 않습니다. 다시 확인 바랍니다.")
        }
      }
    },
    watch: {
      //******************************************************************************
      // 입력 값에 정규표현식 문자 및 "," 표시
      //******************************************************************************
      userPass : function() { 
        return this.registerObj.clntPw = this.registerObj.clntPw.replace(/\s/g,'').replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,'');
      },
      userPassConf : function() {
        return this.registerObj.clntPwConfirm = this.registerObj.clntPwConfirm.replace(/\s/g,'').replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,'');
      },
    }, 
  }   
</script>

<style>
  #singBd{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #00000099;
    background-size: cover;
    z-index: 99999;
    display: none;
  }

  #singBd table {
    font-size: 12px;
  }









  #singPopUp {
    position: absolute;
    width: 1110px;
    height: 800px;
    padding: 30px 46px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: #fff;
    z-index: 999999;
  }

  #singPopUp h1 {
    text-align: center;
    font-family: 'GmarketSansBold';
    font-size: 24px;
  }

  #singPopUp h3 {
    margin-bottom: 10px;
    font-size: 14px;
    letter-spacing: -0.35px;
    color: #444;
  }

  #singPopUp .termsBox {
    width: 100%;
    height: 120px;
    margin-bottom: 10px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    text-align: left;
  }

  #singPopUp .termsBox P {
    height: 120px;
    padding: 10px;
    font-size: 11.8px;
    line-height: 1.5;
    overflow-y: scroll;
  }

  #singPopUp .termsBox P span {
    padding-left: 5px;
    display: inline-block;
  }

  #singPopUp .radioBox {
    text-align: right;
    margin-bottom: 15px;
    font-size: 12px;
  }

  #singPopUp .radioBox input[type="radio"] {
    display: none;
  }

  #singPopUp .radioBox input[type="radio"] + label {
    padding-left: 26.8px;
    position: relative;
    font-weight: 700;
  }

  #singPopUp .radioBox input[type="radio"] + label:before {
    clear: both;
    position: absolute;
    width: 11px;
    height: 11px;
    border: 1px solid #cbcbcb;
    background: #f6f6f6;
    left: 5.8px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    border-radius: 2px;
  }

  #singPopUp .radioBox input[type="radio"]:checked + label:after {
    clear: both;
    position: absolute;
    display: block;
    content: "✔";
    /* content: "\e91c"; */
    /* font-family: "icomoon"; */
    font-weight: 900;
    font-size: 12px;
    left: 8px;
    top: 0px;
    color: #8920ce;
  }


  #singPopUp table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden;
  }

  #singPopUp table td,
  #singPopUp table th {
    border: solid 1px #e5e5e5;
    text-align: left;
  }


  #singPopUp table th {
    width: 119px;
    height: 40px;
    padding-left: 21px;
  }

  #singPopUp table td {
    padding: 6px;
    
  }

  #singPopUp table .tableWi {
    width: 380px;
  }

  #singPopUp table td input,
  #singPopUp table td select {
    padding: 6px 10px;
    border: solid 1px #e5e5e5;
    width: 350px;
  }

  /* #singPopUp table td input[type="text"] {
    margin: 0 4px;
  } */

  /* #singPopUp table td input[type="text"].wMax {
    width: 98%;
  } */

  #singPopUp table td select {
    margin-left: 5px;
  }

  #singPopUp table td input[type="tel"] {
    /* width: 120px; */
    width: 350px;
  }

  #singPopUp table td input[type="password"],
  #singPopUp table td input[type="tel"] {
    margin-right: 10px;
  }

  #singPopUp table td button {
    width: 70px;
    height: 28px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #0b2153;
    color: #0b2153;
    font-weight: 700;
  }

  #singPopUp table td .certain button {
    background: #f0f0f0;
    border: 1px solid #e5e5e5;
    color: #666;
  }

  #singPopUp table td .certain {
    display: inline-block;
  }

  #singPopUp table td .certain input[type="text"] {
    width: 70px;
    margin-left: 10px;
  }

  #singPopUp table td .certain span{
    display: none;
  }


  #singPopUp table input[type="radio"] {
    display: none;
  }

  #singPopUp table input[type="radio"] + label {
    padding-left: 40px;
    font-weight: 700;
    position: relative;
  }

  #singPopUp table input[type="radio"] + label:before {
    clear: both;
    position: absolute;
    width: 11px;
    height: 11px;
    border: 1px solid #cbcbcb;
    background: #f6f6f6;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    border-radius: 2px;
  }

  #singPopUp table input[type="radio"]:checked + label:after {
    clear: both;
    position: absolute;
    display: block;
    content: "\e91c";
    font-family: "icomoon";
    font-weight: 900;
    font-size: 12px;
    left: 21px;
    top: 1px;
    color: #0b2153;
  }

  #singPopUp .signBtnBox {
    margin-top: 80px;
    text-align: center;
  }

  #singPopUp .signBtnBox button {
    width: 137px;
    height: 35px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border-radius: 35px;
    border: none;
  }

  #singPopUp .signBtnBox .signCancleBtn {
    background: #8d8d8d;    
  }

  #singPopUp .signBtnBox .signBtn {
    background: #004381;
    margin-right: 11px;
  }
</style>