import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign.vue')
  },
  {
    path: '/platform',
    name: 'platform',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/platform.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/detail.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/notice.vue')
  },
  {
    path: '/ask',
    name: 'ask',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/ask.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/bypass.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
