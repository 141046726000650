<template>
  <div id="privacyPolicy">
    <div class="modal">
      <h1>
        개인정보 취급방침
      </h1>
      <p>
        <b>애드데이터(ADDATA) 개인정보취급방침</b><br>
        <br>
        <span>
          <b>애드데이터</b> ('addatas.com'이하 '애드데이터')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br>
          &nbsp; ○ 이 개인정보처리방침은 2022년 2월 14부터 적용됩니다.
          <br>
          <br>
          제1조(개인정보의 처리 목적) <b>애드데이터</b> ('addatas.com'이하 '애드데이터')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br>
          <span>
            1. 홈페이지 회원가입 및 관리<br>
            &nbsp;회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.<br>
            2. 민원사무 처리<br>
            &nbsp;민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.<br>
            3. 재화 또는 서비스 제공<br>
            서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.<br>
            4. 마케팅 및 광고에의 활용<br>
            &nbsp;신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br>
            5. 개인영상정보<br>
            &nbsp;범죄의 예방 및 수사, 시설안전 및 화재예방 등을 목적으로 개인정보를 처리합니다.<br>
            6. SNS(네이버,카카오,페이스북)인증<br>
            &nbsp;이용자가 직접 해당 SNS서비스에 로그인하여 회사에게 개인정보 제공을 허용함에 동의해야 하며, 동의 및 인증 후 회사는 이용자가 가입 버튼을 눌러 완료한 후에만 Access Token을 이용해 사용자의 개인정보를 받아 저장합니다. Access Token은 가입시 일회만 사용되며 가입 완료 후 사용자가 제공에 동의한 정보(이름,이메일)를 저장합니다.
          </span>
          <br>
          <br>
          제2조(개인정보의 처리 및 보유 기간)<br>
          <span>
            1. <b>애드데이터</b>는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br>
            2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
            <span>
              원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.<br>
              단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다. <br>
              [ 홈페이지 회원가입 및 관리 ]와 관련한 개인정보는 수집.이용에 관한 동의일로부터 [ 영구 ]까지 위 이용목적을 위하여 보유.이용됩니다.<br>
              - 보존 항목 : 회원가입정보(로그인ID, 이름, 별명)<br>
              - 보존 근거 : 회원탈퇴 시 다른 회원이 기존 회원아이디로 재가입하여 활동하지 못하도록 하기 위함<br>
              - 관련법령 : <br>
              <span>
                1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br>
                2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
                3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br>
                4) 계약 또는 청약철회 등에 관한 기록 : 5년<br>
                5) 표시/광고에 관한 기록 : 6개월
              </span>
            </span>
          </span>
          <br>
          <br>
          <br>
          제3조(개인정보의 제3자 제공)<br>
          <span>
            1. <b>애드데이터</b>는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br>
            2. <b>애드데이터</b>는 귀하의 개인정보를 "개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업·기관에 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.<br>
            <span>
              -통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br>
              -도용방지를 위하여 본인확인에 필요한 경우<br>
              -법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우<br>
              그러나 보다 나은 서비스 제공을 위하여 귀하의 개인정보를 제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 귀하께 제휴사가 누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게 보호·관리되는지에 대해 개별적으로 전자우편 및 서면을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 귀하께서 동의하지 않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다.<br>
              또한 이용자의 개인정보를 원칙적으로 외부에 제공하지 않으나, 아래의 경우에는 예외로 합니다.<br>
              -이용자들이 사전에 동의한 경우<br>
              -법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
            </span>
          </span>
          <br>
          <br>
          제4조(개인정보처리 위탁)<br>
          <span>
            1. <b>애드데이터</b>는 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br>
            2. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
          </span>
          <br>
          <br>
          제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)<br>
          <span>
            1. 정보주체는 <b>애드데이터</b>에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br>
            2. 제1항에 따른 권리 행사는<b>애드데이터</b>에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 <b>파트너스</b>은(는) 이에 대해 지체 없이 조치하겠습니다.<br>
            3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br>
            4. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br>
            5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br>
            6. <b>애드데이터</b>은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
          </span>
          <br>
          <br>
          제6조(처리하는 개인정보의 항목 작성)<br>
          <span>
            <b>애드데이터</b>는 다음의 개인정보 항목을 처리하고 있습니다.<br>
            <span>
              [ 홈페이지 회원가입 및 관리 ]<br>
              필수항목 : 닉네임, 이메일, 휴대전화번호, 비밀번호, 로그인ID, 이름(법인:상호), 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록<br>
              선택항목 : 신용카드정보, 은행계좌정보, 법정대리인 이름, 법정대리인 휴대전화번호
            </span>
          </span>
          <br>
          <br>
          제7조(개인정보의 파기)<br>
          <span>
            회원탈퇴는 내정보->회원탈퇴 메뉴를 통해서 즉시 탈퇴가 가능합니다.<br>
            SNS계정(페이스북,네이버,카카오)을 통한 로그인또한 내정보->회원탈퇴에서 이용이 가능하며 SNS제공업체에서 연동을 해제 할수 있습니다. 그러나 SNS 연동해제를 하더라도 '애드데이터'에 보관중인 고객정보가 삭제되는 것은 아니므로 회원탈퇴를 진행하셔야 개인정보가 파기됩니다.<br>
            <br>
            <span>
              1. <b>애드데이터</b>는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br>
              2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br>
              고객이 탈퇴를 요청하는 즉시 개인정보가 파기가되며 동일 정보의 재가입방지를 위해 고유정보(아이디)를 제외하고는 즉시 파기합니다. <br>
              3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br>
              <span>
                1) 파기절차<br>
                <b>애드데이터</b>는 파기 사유가 발생한 개인정보를 선정하고, <b>애드데이터</b> 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br>
                2) 파기방법<br>
                -전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.<br>
                -종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
              </span>
            </span>
          </span>
          <br>
          <br>
          제8조(개인정보의 안전성 확보 조치)<br>
          <span>
            <b>애드데이터</b>는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br>
            <span>
              1. 정기적인 자체 감사 실시<br>
              &nbsp; 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.<br>
              2. 개인정보 취급 직원의 최소화 및 교육<br>
              &nbsp; 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br>
              3. 내부관리계획의 수립 및 시행<br>
              &nbsp; 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br>
              4. 해킹 등에 대비한 기술적 대책<br>
              &nbsp; <b>애드데이터</b>('애드데이터')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된<br>
              &nbsp; 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br>
              5. 개인정보의 암호화<br>
              &nbsp; 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도<br>
              &nbsp; 보안기능을 사용하고 있습니다.<br>
              6. 접속기록의 보관 및 위변조 방지<br>
              &nbsp; 개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는<br>
              &nbsp; 2년이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.<br>
              7. 개인정보에 대한 접근 제한<br>
              &nbsp; 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의<br>
              &nbsp; 무단 접근을 통제하고 있습니다.<br>
              8. 문서보안을 위한 잠금장치 사용<br>
              &nbsp; 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br>
              9. 비인가자에 대한 출입 통제<br>
              &nbsp; 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br>
            </span>
          </span>
          <br>
          <br>
          제9조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)<br>
          <span>
            1. <b>애드데이터</b>는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.<br>
            2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br>
            <span>
              1)쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br>
              2)쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.<br>
              3)쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
            </span>
          </span>
          <br>
          <br>
          제10조 (개인정보 보호책임자)<br>
          <span>
            <b>애드데이터</b>는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br>
            <span>
            ▶ 개인정보 보호책임자<br>
            성명 :양천수<br>
            직책 :대표<br>
            대표전화 :010-5475-0927, 이메일: adceo@addatas.com, 팩스: 0504-441-0927<br>
            </span>
            ※ 개인정보 보호 담당부서로 연결됩니다.<br>
            <b>애드데이터</b> 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. <b>애드데이터</b>는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
          </span>
          <br>
          <br>
          제11조(개인정보 열람청구)<br>
          <span>
            정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.<br>
            <b>애드데이터</b>은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br>
            <span>
              ▶ 개인정보 열람청구 접수·처리 부서<br>
              성명 :양천수<br>
              직책 :대표<br>
              대표전화 :010-5475-0927, 이메일: adceo@addatas.com, 팩스: 0504-441-0927
            </span>
          </span>
          <br>
          <br>
          제12조(권익침해 구제방법)<br>
          <span>
            정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br>
            <span>
              1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br>
              2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br>
              3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br>
              4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
            </span><br>
            
            「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
          </span>
          <br>
          <br>
          제13조(개인정보 처리방침 변경)<br>
          이 개인정보처리방침은 2022년 4월 1일부터 적용됩니다.
        </span>
      </p>
      <button class="cancleBtn" @click="CanclePrivacy();">
        <i class="fa-solid fa-circle-xmark"></i>
      </button>

    </div>
  </div>
</template>
<script>
  import $          from 'jquery';
  export default {
    methods: {
      CanclePrivacy() {
        $("#privacyPolicy").css({display: "none"})
      }
    }
    
  }
</script>

<style scoped>
  #privacyPolicy {
    display: none;
    position: fixed;
    /* left: 0; */
    top: 0;
    /* width: 800px; */
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
  }

  #privacyPolicy .modal {
    position: relative;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 750px;
    background: #fff;
    transform: translate(-50%, -50%);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 45px;
    border-radius: 10px;
  }

  #privacyPolicy .modal h1 {
    font-size: 14px;
    line-height: 2;
    margin-bottom: 2%;
  }

  #privacyPolicy .modal p {
    width: 100%;
    height: 85%;
    overflow-y: auto;
    line-height: 1.5;
    padding: 15px;
    border: 1px solid #e5e5e5;
    font-size: 12px;
    text-align: left;
  }

  #privacyPolicy .modal p span {
    padding-left: 5px;
  }

  #privacyPolicy .modal .cancleBtn {
    position: absolute;
    background: none;
    right: 10px;
    top: 10px;
    border: none;
    margin-top: 20px;
  }
  #privacyPolicy .modal .cancleBtn i{
    font-size: 20px;
  }

</style>