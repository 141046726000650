<template>
  <div id="logIn">
    <div class="logInBox">
      <a href="javascript:void(0)" @click="logCancle();">
        <i class="fa-solid fa-circle-xmark"></i>
      </a>
      <div class="logInLogo">
        애드데이터
      </div>
      <p>정보를 정확하고 가치있게 생각하는 마케터의 성공 파트너</p>
      <div class="inputBox">
        <div class="leftInput">
            <input type="text" placeholder="이메일을 입력하세요."
            v-model="clntId" @keyup.enter="GoFocus();">
            <input type="password" placeholder="비밀번호를 입력하세요"
            v-model="clntPw" ref="clntPw" @keyup.enter="LogIn();">
        </div>
        <div class="loginBtn">
            <button
              @click="LogIn();"
            >
            로그인
            </button>
        </div>
      </div>
      <div class="logSearch">
        <!-- <input type="checkbox" name="stayLog" id="stayLog"><label for="stayLog"> 로그인 유지</label> -->
        <!-- <ul>
            <li><a href="javascript:void(0)" @click="SearchIdModal();">아이디 / 비밀번호 찾기</a></li>
        </ul> -->
      </div>
    </div>
    <SearchUser></SearchUser>
  </div>
</template>

<script>
import $          from 'jquery';
import axios      from "axios";
import SearchUser from "../components/SearchUser";

export default {
  components: {
    SearchUser
  },
  data() {
    return {
        clntId: ''
      , clntPw: ''
    }
  },
  methods: {
    GoFocus() {
      this.$refs.clntPw.focus(); ///$refs
    },
    //******************************************************************************
    // 로그인 함수
    //******************************************************************************    
    LogIn() {
      axios.post("https://addatas.com:28500/login", {
        clntId: this.clntId,
        clntPw: this.clntPw,
        siteCode: '01'
      })
      .then(response => {
        if( response.data.status == "0" ) {
          this.$store.state.clntId       = response.data.clntId
          this.$store.state.clntNm       = response.data.clntNm
          this.$store.state.nickNm       = response.data.nickNm

          this.$store.state.jwtAuthToken = response.data.authToken
          this.$store.state.adGradeCd    = response.data.gradeCd
          this.$store.state.adLevelCd    = response.data.levelCd

          this.$store.state.adId         = response.data.adId
          this.$store.state.logo         = '';

          // 토큰값을 LocalStorage에 저장한다.
          
          sessionStorage.setItem("clntId", this.$store.state.clntId);
          sessionStorage.setItem("clntNm", this.$store.state.clntNm);
          sessionStorage.setItem("nickNm", this.$store.state.nickNm);
          sessionStorage.setItem("token" , this.$store.state.jwtAuthToken);
          sessionStorage.setItem("grade" , this.$store.state.adGradeCd);
          sessionStorage.setItem("level" , this.$store.state.adLevelCd);
          sessionStorage.setItem("adId"  , this.$store.state.adId);

          this.clntId = '';
          this.clntPw = '';

          // this.$router.go(this.$router.currentRoute);
          $("#logIn").css({display: "none"})
          this.$store.state.menuSelect = 1;
          this.$router.push({ 
            name : 'campaign', 
          })
        } else {
          alert(response.data.message);
          this.clntPw = '';
        }
      })
      .catch(error => {
        console.log(error);
      })
    },

    logCancle(){
      $("#logIn").css({display: "none"})
    },

    //******************************************************************************
    // 아이디 / 비밀번호 팝업 함수
    //******************************************************************************
    SearchIdModal() {
      $("#searchModar").css({display: "block"})
    }
  },
  created() {
    this.clntId = '';
     this.clntPw = '';
  }
}
</script>

<style scoped>
  #logIn {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #00000099;
    background-size: cover;
    z-index: 99999;
    display: none;
  }

  #logIn .logInBox {
    position: absolute;
    width: 460px;
    height: 280px;
    padding: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
  }
  #logIn > .logInBox > a {
    position: absolute;
    right: 20px;
    top: 20px;
    font-weight: 700;
  }

  #logIn > .logInBox > a > i {
    font-size: 25px;
    color: #0b2153;
  }

  #logIn > .logInBox > .logInLogo,
  #logIn > .logInBox > p {
    text-align: center;
    font-weight: 700;
    font-size: 35px;
  }

  #logIn > .logInBox > .logInLogo {
    margin-bottom: 10px;
    font-family: 'GmarketSansBold';
  }



  #logIn > .logInBox > p {
    font-size: 14px;
    letter-spacing: -0.49px;
    font-weight: 700;
    color: #222;
    margin-bottom: 56px;
  }

  #logIn .logInBox .inputBox {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    height: 100px;
    margin-left: -20px;
  }


  #logIn .logInBox .inputBox .leftInput input {
    width: 250px;
    height: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 11px 20px;
  }

  #logIn .logInBox .inputBox .leftInput input[type="text"] {
    margin-bottom: 10px;
  }

   #logIn .logInBox .inputBox .loginBtn button {
     width: 120px;
     height: 100%;
     border-radius: 20px;
     background: #262626;
     color: #fff;
     font-size: 18px;
     border: none;
     font-weight: 700;
     margin-right: 10px;
   }

  #logIn .logInBox .logSearch {
    clear: both;
    height: 35px;
    padding-bottom: 20px;
  }

  #logIn .logInBox .logSearch input[type="checkbox"] {
    display: none;
  }

  #logIn .logInBox .logSearch input[type="checkbox"] + label {
    color: #444;
    font-weight: 700;
    padding: 6px 6px 6px 26px;
    position: relative;
  }

  #logIn .logInBox .logSearch input[type="checkbox"] + label:before {
    clear: both;
    position: absolute;
    width: 20px;
    height: 20px;
    content: "\e91c";
    font-family: "icomoon";
    color: #c9c9c9;
    font-weight: 900;
    font-size: 15px;
    border: 2px solid #c9c9c9;
    border-radius: 50%;
    text-align: center;
    box-sizing: border-box;
    padding-top: 1px;
    left: 0px;
    top: 3px;
  }
  #logIn .logInBox .logSearch input[type="checkbox"]:checked + label:before {
    color: #0b2153;
    border: 2px solid #0b2153;
  }
  #logIn .logInBox .logSearch ul {
    float: right;
  }
  #logIn .logInBox .logSearch ul li {
    float: left;
    padding: 0 11px;
    /* position: relative; */
    list-style: none;
    text-decoration: none;
  }
  #logIn .logInBox .logSearch ul li:before {
    clear: both;
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #e5e5e5;
    text-decoration: none;
  }
  #logIn .logInBox .logSearch ul li:first-child:before {
    display: none;
  }
  #logIn .logInBox .socialBox {
    position: relative;
  }
  #logIn .logInBox .socialBox p {
    padding: 19px 0;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.42px;
    color: #444;
  }
  #logIn .logInBox .socialBox .socialBox2 {
    display: flex;
    justify-content: space-between;
  }
  #logIn .logInBox .socialBox .socialBox2 a {
    display: flex;
    width: 120px;
    height: 40px;
    border: 1px solid #eee;
    border-radius: 15.5px;
    padding: 0 18px;
    justify-content: space-around;
    align-items: center;
    font-weight: 700;
    color: #666;
  }
  #logIn .logInBox .socialBox .socialBox3{
    position: absolute;
    width: 100%;
    height: 40px;
    border-radius: 15.5px;
    text-align: center;
    font-weight: 700;
    padding: 13px;
    border: 1px solid #eee;
    color: #000;
    background: #ffffffd9;
    text-shadow: 0 0 5px #fff;
  }
</style>